import React from 'react'
import classes from './Error.module.css';
function Error() {
  return (
    <div className={classes.main}>
      <h1 >OOps!</h1>
      <h1>Something went wrong....!</h1>
    </div>
  )
}

export default Error